/* eslint-disable camelcase */
import { useState, useMemo } from 'react';
import { reduce, every } from 'lodash';

import { constants } from '@skunexus/order-details';

import useItems from './hooks/useItems';

const { CANCEL_METHOD, DROPSHIP_DELIVERY_METHOD } = constants;

const useDecidableItems = ({
    order = {},
    items = [],
    customGraphqlFields = {},
    saveDecisions = () => {},
    errorNotification = () => {},
}) => {
    const { id: orderId } = order;
    const [isSubmitting, setSubmitting] = useState(false);
    const [isCancelModalOpen, setCancelModalOpen] = useState(false);
    const [
        { decidableItems },
        {
            addSplit,
            removeSplit,
            changeQty,
            changeMethod,
            changeMethodAll,
            changeLocation,
            changeLocationAll,
            changePrice,
            changeVendorPrice,
        },
    ] = useItems({ items });

    // eslint-disable-next-line no-shadow
    const getCancelItems = (items) =>
        items.filter(
            ({ decisions }) =>
                !!decisions.filter(
                    ({ id, delivery_method, qty }) => qty > 0 && delivery_method === CANCEL_METHOD && !!id,
                ).length,
        );

    const handleSubmit = () => {
        const itemsForPayload = decidableItems
            .map(({ itemId, decisions = [] }) => ({
                item_id: itemId,
                decisions: decisions
                    .filter(({ id, delivery_method, qty }) => qty > 0 && !!delivery_method && !!id)
                    .map(({ id, delivery_method, qty, vendorPrice }) => ({
                        id,
                        delivery_method,
                        qty,
                        vendor_price: vendorPrice,
                    })),
            }))
            .filter(({ decisions = [] }) => decisions.length);

        const isVendorPriceFulfill = every(
            itemsForPayload,
            ({ decisions = [] }) =>
                !decisions.filter(({ delivery_method, vendor_price }) => {
                    if (delivery_method === DROPSHIP_DELIVERY_METHOD) {
                        return vendor_price === null || vendor_price === '' || vendor_price < 0;
                    }

                    return false;
                }).length,
        );

        if (!isVendorPriceFulfill) {
            errorNotification('No all prices are fulfilled.');
            return;
        }

        setSubmitting((state) => !state);
        saveDecisions({
            items: itemsForPayload,
            orderId,
            customGraphqlFields,
            onSuccess: () => {
                setSubmitting((state) => !state);
                setCancelModalOpen(false);
            },
            onFailure: () => {
                setSubmitting((state) => !state);
            },
        });
    };

    const handleCreateFulfillment = () => {
        const isCancelDecisionInPayload = !!getCancelItems(decidableItems).length;

        if (isCancelDecisionInPayload) {
            setCancelModalOpen(true);
        } else {
            handleSubmit();
        }
    };

    const totalItems = useMemo(
        () =>
            reduce(
                decidableItems.filter(({ isChild = false }) => isChild),
                (sum, { totalItemQty: qty = 0 }) => sum + qty,
                0,
            ),
        [decidableItems],
    );

    const totalWeight = useMemo(
        () =>
            reduce(
                decidableItems.filter(({ isChild = false }) => isChild),
                (sum, { totalItemQty: qty = 0, product: { weight = 0 } = {} }) => sum + qty * weight,
                0,
            ),
        [decidableItems],
    );

    const getCancelModalProps = () => ({
        isOpen: isCancelModalOpen,
        isLoading: isSubmitting,
        items: getCancelItems(decidableItems).filter(({ isChild = false }) => isChild),
        onCancel: () => setCancelModalOpen(false),
        onConfirm: handleSubmit,
    });

    return [
        { decidableItems, totalItems, totalWeight, isSubmitting },
        {
            addSplit,
            removeSplit,
            changeQty,
            changeMethod,
            changeMethodAll,
            changeLocation,
            changeLocationAll,
            changePrice,
            changeVendorPrice,
            handleCreateFulfillment,
            getCancelModalProps,
        },
    ];
};

export default useDecidableItems;
