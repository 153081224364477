import { useEffect, useState } from 'react';
import { ajaxExceptionMessage } from '@skunexus/utils';
import { get, isNumber } from 'lodash';

import useVendorProduct from './hooks/useVendorProduct';

const useVendorPrice = ({
    productId = '',
    vendorId = '',
    itemId = '',
    index = '',
    value: propsValue = '',
    changeVendorPrice = () => {},
    errorNotification = () => {},
}) => {
    const [{ error, isLoading }, { fetchVendorProduct }] = useVendorProduct();
    const [value, setValue] = useState();

    useEffect(() => {
        setValue(propsValue);
    }, [propsValue]);

    useEffect(() => {
        if (error) {
            errorNotification(ajaxExceptionMessage(error));
        }
    }, [error]);

    useEffect(() => {
        const fetchVendorProductData = async () => {
            const vendorProduct = await fetchVendorProduct({ productId, vendorId });
            if (vendorProduct) {
                changeVendorPrice({ itemId, index, price: get(vendorProduct, 'price', '') || '' });
            }
        };
        if (vendorId && productId) {
            fetchVendorProductData();
        }
    }, [vendorId]);

    const handleChange = (e) => {
        // eslint-disable-next-line no-shadow
        const { value } = e.target;

        const price = value ? parseFloat(value) : value;

        if (isNumber(price) && price < 0) {
            errorNotification(`Price can't be less then 0.`);
            return;
        }

        setValue(price);
        changeVendorPrice({ itemId, index, price });
    };

    return [{ isLoading, value }, { handleChange }];
};

export default useVendorPrice;
