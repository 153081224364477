/* eslint-disable no-shadow */
import { useState } from 'react';
import { get, keys, isEmpty, reduce, some, every, sumBy } from 'lodash';
import { ajaxExceptionMessage } from '@skunexus/utils';
import * as API from './api';

import { CANCEL_RESOLVE_OPTION } from '../../../../constants';

const useResolve = ({
    poId,
    errorNotification = () => {},
    successNotification = () => {},
    onCancel = () => {},
    onSuccess = () => {},
}) => {
    const [isSubmitting, setSubmitting] = useState(false);

    const getPayloadItems = (_items) => {
        const payloadItems = reduce(
            _items.filter(({ isChecked }) => isChecked),
            (result, item) => {
                const { id, items = [] } = item;

                const payloadItem = items
                    .filter(({ qty }) => qty)
                    .map(({ qty, replacementProductId, vendorPrice, itemPrice, vendorId }) => {
                        if (replacementProductId === CANCEL_RESOLVE_OPTION) {
                            return {
                                product_id: replacementProductId,
                                qty,
                            };
                        }
                        return {
                            product_id: replacementProductId,
                            qty,
                            customer_price: itemPrice,
                            vendor_price: vendorPrice,
                            vendor_id: vendorId || null,
                        };
                    });

                result[id] = [...get(result, id, []), ...payloadItem];

                return result;
            },
            {},
        );

        return reduce(
            keys(payloadItems),
            (result, id) => {
                const items = payloadItems[id];

                if (items.length) {
                    result[id] = [...payloadItems[id]];
                }

                return result;
            },
            {},
        );
    };

    const isAllItemsHasSelectedAction = (items) =>
        every(
            items
                .filter(({ isChecked }) => isChecked)
                .map(({ items = [] }) =>
                    every(
                        items.filter(({ qty }) => qty),
                        ({ replacementProductId }) => !!replacementProductId,
                    ),
                ),
            (i) => i,
        );

    const isAllItemsHasSelectedQtyAndPrices = (items) =>
        every(
            items
                .filter(({ isChecked }) => isChecked)
                .map(({ items = [] }) =>
                    every(
                        items,
                        ({ qty, itemPrice, vendorId, vendorPrice }) =>
                            qty !== '' && itemPrice !== '' && ((vendorId && vendorPrice !== '') || !vendorId),
                    ),
                ),
            (i) => i,
        );

    const isAllItemsHasCorrectQty = (items) =>
        every(
            items
                .filter(({ isChecked }) => isChecked)
                .map(({ items = [], qty: totalQty }) => totalQty === sumBy(items, ({ qty }) => qty)),
            (i) => i,
        );

    const isResolveAllowed = (items) => some(items, ['isChecked', true]) && isAllItemsHasSelectedQtyAndPrices(items);

    const resolve = async (_items) => {
        // if (!isAllItemsHasCorrectQty(_items)) {
        //     errorNotification('Set correct qty for all items.');
        //     return;
        // }

        if (!isAllItemsHasSelectedAction(_items)) {
            errorNotification('Select action for all items.');
            return;
        }

        const items = getPayloadItems(_items);

        if (isEmpty(items)) {
            errorNotification('Nothing to resolve.');
            return;
        }

        setSubmitting(true);

        try {
            await API.resolvePO({
                id: poId,
                data: {
                    items,
                },
            });
            setSubmitting(false);

            successNotification('Purchase Order is resolved');
            onSuccess();
        } catch (e) {
            setSubmitting(false);
            errorNotification(ajaxExceptionMessage(e));
            onCancel();
        }
    };

    return [
        {
            isSubmitting,
        },
        { isResolveAllowed, resolve },
    ];
};

export default useResolve;
